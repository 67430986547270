
import AvatarFlair from "@/components/templateux/user/avatar-flair";
import AvatarFlairLive from "@/components/templateux/user/avatar-flair-live";
import { hasData } from "@/lib/utils/hasData";


export default function ProfileAvatarLive({
  profiledata
}) {



  return (<>
    <div className={`flex flex-col items-center content-center justify-center text-center relative group cursor-pointer 
    hover:bg-gray-700 p-2 rounded-md`}>
      <div className=" flex-0 px-3"
        title={profiledata?.user_name}>
        <AvatarFlairLive 
            
            isPlaying={(profiledata?.stream_status?.toString()=="1" 
                        && (!hasData(profiledata?.bol_send_notifications) 
                        || profiledata?.bol_send_notifications?.toString() == "1")
                        )}
            isContributor={profiledata?.contributor_level > 0}
            avatar_url={profiledata?.avatar_url}
            avatar_url_sfw={profiledata?.avatar_url_sfw}
            bol_nsfw={profiledata?.bol_nsfw}
            avatar_crdate={profiledata?.avatar_crdate}
            id={profiledata?.id}
            user_name={profiledata?.user_name}
            
            containerClass={`w-[64px] h-[64px]`}
            sizes={{
                          width: 64
                          ,height: 64
                          ,quality: 100
                          }}
            bgClass={`w-[64px] h-[64px]`}
            avatarClass={`border-4 border-transparent w-[64px] h-[64px]`}
            rotaryContainerClass={`w-[64px] h-[64px]`}
            rotaryInternalClass={`w-[72px] h-[72px] -mr-[4px]`}
          />
      </div>
      <div className="font-bold text-2xs mt-5">
        {profiledata?.user_name}
      </div>

    </div>
</>)


}