import React, { useEffect, useState } from "react";
import ButtonLinkPrimary from "@/components/templateux/button/button-link-primary";
import LoaderToggle from "@/components/templateux/loading/loader-toggle";
import { useSession } from "next-auth/client";
import { useUserContext } from "@/lib/utils/hooks/useUserContext";
import parseUserJson from "@/lib/utils/admin/parseUserJson";
import Link from "next/link";
import { openWindow } from "@/lib/utils/openWindow";
import ButtonSecondary from "../templateux/button/button-secondary";
import { config, marketing } from "@/lib/config";
import RotaryLogo from "../templateux/rotary-logo";
import { useUserAuth } from "@/lib/utils/hooks/useUserAuth";


export default function HeroCTA({}) {

  const [ session, loading ] = useSession()
  const [ IsMounted, set_isMounted ] = useState<boolean>()
  const auth = useUserContext()
  const userdata = parseUserJson(JSON.stringify(auth))
  const { userAuthData, isUserAuthLoading, isUserAuthError } = useUserAuth()

  useEffect(() => {
    set_isMounted(true)
  }, [])

    const rotaryicon=(<>
                  <RotaryLogo 
                      classSize='w-7 h-7 mx-auto '
                      classAnimate=' animate-spin-slow'
                      bgColor='rgba(220,38,38,0)'
                      centerColor='rgba(220,38,38,1)'
                      pointerColor='#111827'
                      holeColor='rgb(220,38,38)'
                      dialerColor='#ffffff'
                      size='64'
                  />
              </>)

    return (<>
                      
          <div className={`py-5 overflow-hidden  

                        border-gray-700
                          `}>
            <div className="relative mx-auto px-4 sm:px-6 xl:px-8">
              

            {(isUserAuthLoading || loading || !session) &&
              <div className="sm:flex items-center content-center block">

                
                
      
                    <div className="flex-1  sm:text-left">

                          <h1 className={`text-xl sm:text-2xl leading-8 font-extrabold tracking-tight text-center sm:text-left
                                           text-gray-100
                          `}>
                           
                            {process.env.NEXT_PUBLIC_MARKETING_HEADLINE}
                          </h1>
                        
                        
                        <p className="mx-auto text-base sm:text-lg text-center sm:text-left  mt-2
                                       text-gray-300
                        ">
                          
                          {process.env.NEXT_PUBLIC_MARKETING_DESCRIPTION}
                          
                        </p>
                        

                    </div>
                    
                    <div className="flex-0 block xs:flex mx-auto sm:block xl:flex sm:ml-10 items-center content-center  max-w-min  ">

                       

                          <div className="flex-0 max-w-min mt-3 mx-auto ml-0 xs:ml-3 sm:ml-0 xl:ml-3">

                          {loading &&
                             <Link href="/app/auth/register">
                               <a>
                              <ButtonSecondary
                                classColor={`bg-red-600 hover:bg-red-500 text-white animate-pulse`}
                                onClick={()=> {}}
                                message={marketing.cta}
                                icon={rotaryicon}
                              />
                              </a>
                            </Link>
                            }

                          {!loading && !session &&
                             <Link href="/app/auth/register">
                               <a>
                              <ButtonSecondary
                                classColor={`bg-red-600 hover:bg-red-500 text-white`}
                                onClick={()=> {}}
                                message={marketing.cta}
                                icon={rotaryicon}
                              />
                              </a>
                            </Link>
                            }

                            
                          </div>

                    </div>
                    
                </div>
                }

                {(!isUserAuthLoading && !loading && session) &&
                 <div className="flex">

                
                
      
                    <div className="flex-1  sm:text-left">

                          <h1 className={`text-xl sm:text-2xl leading-8 font-extrabold tracking-tight text-center 
                                           text-gray-100
                          `}>
                           
                            <>
                            Welcome back, <Link href={`/${userAuthData?.user_name}`}>
                                <a className='underline hover:no-underline cursor-pointer'>
                                  @{userAuthData?.user_name}
                                </a>
                              </Link>!
                            </>
                          
                           
                          </h1>
                        
                    </div>
                    

                </div>
                }

           

            

            </div>

            
        </div>

        
        </>)
}