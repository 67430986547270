
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import PostStateBreakdown from "./post-state-breakdown";
import { useSWRInfinite } from "swr";
import LoadingRotary from "@/components/templateux/loading/loading-rotary";

const fetcher = (url) => fetch(url).then((res) => res.json());
const PAGE_SIZE = 6;

export default function PostArray({

   itemdata
  ,placeholderdata=[]
  ,isLoading = false
  ,isError = false
  ,userdata
  ,ownerdata
  ,display_view
  ,set_display_view
  ,cache
  ,set_cache
  ,slugfilter
  ,nodata
  ,navtype
  ,helptype
  ,isAdmin
  ,slug
  ,title
  ,swr_url=`/api/private/post/select?option=user_id&id=${ownerdata?.id}`
  ,descriptionRef
}) {


    const [itemdata_active,set_itemdata_active] = useState(itemdata)
    const [data_loaded,set_data_loaded] = useState(false)

    

    //swr infinite stuff
    const swr = useSWRInfinite((index) =>
      `${swr_url}&page=${index + 1}`,
    fetcher);

    const { data, error, mutate, size, setSize, isValidating } = swr;

    

    const posts = data ? [].concat(...data) : itemdata_active ;
    const isLoadingInitialData = !posts;
    const isLoadingMore = isLoadingInitialData || (size > 0 && data && data[0] && typeof data[0][size - 1] === "undefined");
    const isEmpty = !data || (data && !data[0]) || (data && data[0] && data[0]?.length === 0);
    const isReachingEnd = isEmpty || (data && data[0][data.length - 1]?.length < PAGE_SIZE);
    const isRefreshing = isValidating && data && data[0] && data[0].length === size;

    const router = useRouter();

    useEffect(()=> {
      if (!data_loaded && data) {
        //set_itemdata_active(data);
        set_data_loaded(true)
      }
    },[
      data_loaded,
      data
    ])

  if (isError) {
    return <div className="px-4">Something went wrong...</div>;
  }

  if (!isLoading && !isError && 
      (!posts 
        || (posts && (display_view=="audio" 
                      ? posts.filter(e=>["2","3","5"].indexOf(e?.post_type?.toString()) > -1) //filter out audio
                      : posts)?.length == 0)
      )
      
      
    
    ) {
      return (<>
              <div className="w-full px-4 py-5 mt-3 text-md text-center text-white font-bold">
                {nodata}
              </div>
              </>)
    }
    


  return (
    <>
       



      
        
      {(!isLoading && !isError && 
      (!posts || (posts && posts?.length > 0))) &&
      <div className="p-2 bg-gray-900">

       
        
        {/* {isEmpty ? <p>{nodata}</p> : null} */}

        {/* <InfiniteScroll
            swr={swr}
            loadingIndicator={<></>}
            endingIndicator={<div className="text-center my-5 italic">
                                <div>
                                {nodata}
                                </div>
                                <div className="flex justify-center my-4">
                                    <div
                                        onClick={() => {
                                          document.body.scrollTop = 0; // For Safari
                                          document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                                        }}
                                        className="cursor-pointer bg-blue-600 text-white font-bold px-8 py-3  rounded-full"
                                    >
                                    Back to Top
                                    </div>
                                </div>
                              </div>}
            // isReachingEnd={(swr) =>
            //   swr[0]?.length === 0 || swr[swr?.length - 1]?.length < PAGE_SIZE
            // }
            isReachingEnd={isReachingEnd}
          > */}

          

      {posts?.map((e,index) => 
      
      <PostStateBreakdown
        key={index}
        headline={'All Posts'}
        itemdata={isLoading ? placeholderdata : e}
        itemdataisLoading={isLoading}
        itemdataisError={isError}
        userdata={userdata}
        ownerdata={ownerdata}
        display_view={display_view}
        cache={cache}
        set_cache={set_cache}
        nodata={nodata}
        navtype={navtype}
        helptype={helptype}
        slug={slug}
        router={router}
        descriptionRef={descriptionRef}
        index={e?.post_id}
      />
    
    
      )}
      {/* </InfiniteScroll> */}
      </div>
    }

    {!isReachingEnd &&
    <div className="flex justify-center my-4">
        <div
            onClick={() => {
                if (!isLoadingMore && !isReachingEnd) setSize(size + 1)
            }}
            className={`
            ${!isLoadingMore ? "animate-pulse" : ""}
            cursor-pointer bg-blue-600 text-white font-bold h-12 w-48 justify-center mx-auto flex items-center content-center rounded-full`}
        >
        {isLoadingMore
            ? <div className="flex-1 flex items-center content-center text-center justify-center">
                  <div className="flex items-center content-center">
                      <div className="flex-0 flex  rounded-md">
                      
                        <LoadingRotary 
                            classSize={`w-8 h-8 mx-auto block`}
                            classAnimate='animate-spin-slow'
                            bgColor='#00000000'
                            centerColor='#000000'
                            pointerColor='#ffffff'
                            holeColor='#000000aa'
                            dialerColor='#ffffffaa'
                            size="64"
                        />
                    </div>
                </div>
                <div className="ml-2 ">
                  Loading...
                </div>
              </div>
            : "load more"}
        </div>


        

    </div>
    }

      </>
    );
  }



